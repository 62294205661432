

function Ajax(data = {what:"",select:"",where:"",multiple:false},callback=()=>{})
{
    let {what,select,where,multiple} = data
    if(typeof data === "string")
    {
        what = data;
    }
    if(Array.isArray(select))
    {
       multiple = true;
    }
    window.$ajax({
        method:"POST",
        file:"get",
        body:JSON.stringify({what,select,where,multiple})
    },(e)=>{
        callback(e)
    })
}


function sendMail(data = {to:"",text:"",subject:"",infoEmail:"",button:"",buttonLink:"",headText:"",color:"",addPeople:""},callback=()=>{}) {
    let key = "0540b8d81bdeca8290e5c8d058bb99eb11607816f4f8790d35dc2f6a329375512c7329a1c47b073e696de2f6f48cff488b7bd9cc1f7b499ae987690669e0361a"
    let {to,text,subject,infoEmail,headText,button,buttonLink,color,addPeople} = data
    
    window.$ajax({
        method:"POST",
        file:"/email/send",
        body:JSON.stringify({to,text,subject,infoEmail,headText,button,buttonLink,color,key,addPeople})
    },(e)=>{
        callback(e)
    })

}

function verifaceCreate({id},callback = ()=>{}) {

    fetch("https://api.veriface.eu/public-api/v1/verification/create",{
        method:"POST",
        headers:{
            ApiKey: "vuxi71WbXXcfChjj",    
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
                "type": "LINK_SHORT",
                "companyId": "0832187a-5791-4621-abc5-2c04ef865df7",
                "templateId": "3660069a-bcbb-4781-874f-34584ca98be6",
                "personId": "f3ed3043-3168-457e-9d8d-bf400100787d",
                "referenceId": id,
                "endUserLocale": "sk_SK",
                "locale": "sk_SK"
        })
    })
    .then((e)=>e.json())
    .then(callback)
    
}

export {Ajax,sendMail,verifaceCreate}